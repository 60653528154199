<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />

        <div v-for="role in user_roles" :key="role.id" class="user-role grid">
            <label class="modal-heading-2" v-html="role.name" />
            <Button stretch color="accent" :label="resource[lang].edit" @click="showUserRoleEditModal(role)" />
        </div>

        <UserRoleEditModal
            v-if="showUserRoleEditModalFlag"
            :user_role="edit_user_role"
            @update="updateUserRole"
            @cancel="cancelUserRoleEditModal"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/project-user-roles-resource'

import Button from '@/components/basic/Button'
import UserRoleEditModal from '@/components/modals/UserRoleEditModal'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        UserRoleEditModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            user_roles: [],

            showUserRoleEditModalFlag: false,
            edit_user_role: null,

            resource
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        async init() {
            this.$store.dispatch('loading', true);
            const response = await this.getUserRolesRequest();
            this.$store.dispatch('loading', false);

            this.user_roles = response.data;
        },

        // Requests
        getUserRolesRequest() {
            return promiseGetRequest('user-roles');
        },

        // Actions
        showUserRoleEditModal(role) {
            this.showUserRoleEditModalFlag = true;
            this.edit_user_role = role;
            this.$store.dispatch('modal', true);
        },
        cancelUserRoleEditModal() {
            this.showUserRoleEditModalFlag = false;
            this.edit_user_role = null;
        },
        updateUserRole(user_role) {
            const index = this.user_roles.findIndex(role => user_role.id == role.id);

            this.user_roles[index] = user_role;

            this.showUserRoleEditModalFlag = false;
            this.edit_user_role = null;
        },
    }
}
</script>

<style lang="scss" scoped>

.user-role {
    background-color: var(--whit);

    grid-template-columns: 3fr 1fr;

    padding: 1rem;

    label {
        margin: 0;

        height: 100%;

        display: flex;
        align-items: center;
    }
}

.user-role + .user-role {
    margin-block-start: 1rem;
}

</style>