const resource = {
    en: {
        heading: 'User Roles',
        edit: 'Edit',
    },
    de: {
        heading: 'Benutzerrollen',
        edit: 'Bearbeiten',
    }
}

export default resource;