<template>
    <div class="textarea-input">
        <textarea
            type="text" 
            :value="modelValue" 

            placeholder=" "
            :name="input_field_name"
            :readonly="readonly"
            :required="required"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <label :for="input_field_name" v-html="label" />
        <button 
            v-if="commented && user" 
            :class="['indicator', unresolved_comment && 'unresolved']" 
            @click.prevent="$emit('showCommentModal', input_field_name)"
        >|
            <q-tooltip v-if="!unresolved_comment" v-html="resource[lang].commented" />
            <q-tooltip v-if="unresolved_comment" v-html="resource[lang].unresolved" />
        </button>
        <q-icon 
            v-if="commentable && user"
            name="fas fa-ellipsis-v" 
            size="1rem" 
            color="dark_grey"
            class="comment-button"
            tabindex="0"
            @click="$emit('showCommentModal', input_field_name)"
        >
            <q-tooltip v-html="resource[lang].comment" />
        </q-icon>
        <q-icon 
            v-if="help_text"
            name="fas fa-question" 
            size="1rem" 
            color="dark_grey"
            class="help-button"
            tabindex="0"
            @click="$emit('showHelpModal', help_text)"
        >
            <q-tooltip v-html="resource[lang].show_help" />
        </q-icon>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/input-text-resource'

export default {
    props: {
        modelValue: String,

        commentable: Boolean,
        commented: Boolean,
        unresolved_comment: Boolean,

        input_field_name: String,

        label: String,
        readonly: Boolean,
        required: Boolean,
        help_text: String,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    computed: {
        ...mapGetters(['user'],)
    },
    emits: ['showHelpModal', 'showCommentModal', 'update:modelValue']
}
</script>

<style lang="scss" scoped>

textarea {
    display: block;
    width: 100%;
    min-height: 15rem;

    background: var(--whit);
    border: none;
    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;

    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-block-start: 2rem;
    padding-block-end: 0.5rem;
}

textarea:focus-visible {
    outline: none;
}

textarea:focus + label {
    color: var(--accent);

    top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
}

textarea:not(:placeholder-shown) + label {
    top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
}

label {
    position: absolute;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    top: 1.25rem;

    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    pointer-events: none;
}

.comment-button {
    display: none;

    position: absolute;
    top: 0;
    left: -1.5rem;

    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;

    cursor: pointer;
}

.comment-button:hover,
.comment-button:focus-visible,
.help-button:hover,
.help-button:focus-visible {
    color: var(--blak) !important;
}

.help-button {
    position: absolute;

    right: 1rem;
    top: 0;

    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;

    cursor: pointer;
}

.indicator {
    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;

    background-color: var(--primary);
    color: var(--primary);
    border: none;

    width: 10px;
    height: calc(100% - 10px);

    margin: 5px;
}

.indicator:hover, 
.indicator:focus-visible {
    background-color: var(--accent);
    color: var(--accent);
}

.textarea-input {
    position: relative;
}

.textarea-input:hover .comment-button {
    display: block;
}

.unresolved {
    background-color: var(--light_blue);
    color: var(--light_blue);
}

</style>