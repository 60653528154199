const resource = {
    en: {
        heading: 'Edit User Role',
        name: 'Name',
        description: 'Description',
        save: 'Save',
        discard: 'Discard'
    },
    de: {
        heading: 'Userrolle bearbeiten',
        name: 'Name',
        description: 'Beschreibung',
        save: 'Speichern',
        discard: 'Abbrechen'
    }
}

export default resource;