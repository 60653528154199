<template>
    <BaseModal ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <form @submit.prevent="saveUserRole">
            <InputText :label="resource[lang].name" v-model="edit_user_role.name" required />
            <InputTextArea :label="resource[lang].description" v-model="edit_user_role.description" />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].save" submit />
                <Button color="white" :label="resource[lang].discard" @click="closeModal" />
            </div>
        </form>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/user-role-edit-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        user_role: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            edit_user_role: null,

            resource
        }
    },
    created() {
        this.edit_user_role = this.user_role;
    },
    methods: {
        // Requests
        updateUserRoleRequest(request_body) {
            return promisePutRequest(`user-role/${request_body.id}`, request_body);
        },

        // Actions
        async saveUserRole() {
            this.$store.dispatch('loading', true);
            const response = await this.updateUserRoleRequest(this.edit_user_role);
            this.$store.dispatch('loading', false);

            this.$emit('update', response.data.user_role);
            this.$refs.base_modal.closeModal();
        },
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        }
    },
    emits: ['update', 'cancel']
}
</script>

<style lang="scss" scoped>

form > * + * {
    margin-block-start: 1rem;
}

.actions {
    justify-content: space-between;
}

</style>